import api from "..";

export async function getRoomDetail(id) {
    try {
      const response = await api.get(`/salistas/${id}`);
      return response;
    } catch (error) {
      console.error(error);
    }
}
