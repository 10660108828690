import React, {useEffect, useState} from 'react'
import { getHomeCategories } from '../../../services/api/requests/home-categories'
import { CategoriesMenuContainer, CategoriesMenuItem } from './categories-menu.styles'

const CategoriesMenu = ({isActive}) => {
    const [opacityState, setOpacityState] = useState(false)
    const [categories, setCategories] = useState()
    useEffect(() => {
        getHomeCategories()
            .then(data => {setCategories(data.data)})
    }, [])

    useEffect(() => {
        if(isActive){
            setOpacityState(true)
        } else{
            setOpacityState(false)
        }
    }, [isActive])
    
    return (
        <CategoriesMenuContainer isActive={isActive} opacityState={opacityState}>
            {categories?.data.items.map((item, index) => {
                return(
                    <CategoriesMenuItem key={index} href={`/resultado?search=&category=${item.id}`}>{item.name}</CategoriesMenuItem>
                )
            })}
        </CategoriesMenuContainer>
    )
}

export default CategoriesMenu