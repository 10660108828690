import styled from 'styled-components'

export const ResultSearchContainer = styled.section`
    *{
        box-sizing: content-box;
    }
`

export const InputsRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 40px;
    @media (min-width: 1200px){
        flex-direction: row;
        padding: 0;
    }
`
export const ContainerInput = styled.div`
position: relative;
flex:1;
@media (min-width: 1200px){
        margin-right: 20px;
        margin-bottom: 0;
    }


`

export const LabelInput = styled.label`
        font-size: 18px;
        letter-spacing: 3px;
        text-transform: uppercase;

        position: absolute;
		pointer-events: none;
		top: 0px;
		left: 4px;
		transition: all 0.1s ease;

        .input-base:focus ~ &,
        .input-base:not(:focus):valid ~ & {
            top: -15px;
            bottom: 0px;
            left: 0px;
            font-size: 11px;
            opacity: 1;
        }
	
`

export const ResultSearchInput = styled.input.attrs({ required: true })`
    border: 0;
    outline: none;
    border-bottom: 1px solid ${props => props.theme.primary};
    color: ${props => props.theme.primary};
    background-color: transparent;
    font-size: 18px;
    margin-bottom: 40px;
    width: 100%;
    margin-right: 0;

    &::placeholder{
        color: ${props => props.theme.primary};
        letter-spacing: 3px;
        text-transform: uppercase;;
    }
`

export const ResultSearchSelect = styled.select.attrs({ required: true })`
    border: 0;
    outline: none;
    border-bottom: 1px solid ${props => props.theme.primary};
    color: ${props => props.theme.primary};
    background-color: transparent;
    font-size: 18px;
    margin-bottom: 40px;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-right: 0;
 
    &::placeholder{
        color: ${props => props.theme.primary};
        letter-spacing: 3px;
        text-transform: uppercase;;
    }
    option{
        letter-spacing: 3px;
        text-transform: uppercase;
        &:hover{
            background: ${props => props.theme.cream}; 
        }
    }
    option:not(:checked) { background: ${props => props.theme.cream}; }
    option:checked { 
        background-color: ${props => props.theme.secondary} ;
        color: #fff;
    }
`

export const ResultSearchButton = styled.div`
    border: 1px solid ${props => props.theme.secondary};
    border-radius: 20px;
    padding: 5px 20px;
    color: ${props => props.theme.primary};
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 300;
    outline: none;
    transition: 400ms;
    text-align: center;
    text-decoration: none;
    height:fit-content ;
    cursor: pointer;
    &:hover{
        background-color: ${props => props.theme.secondary};;
    }
`