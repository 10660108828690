import React, { useState, useEffect } from 'react'
import { EmptyListMessage, LetterItem, LettersBar, LineButton, LineTitle, SearchResultsContainer, SearchResultsContent, SearchResultsTitle, StoreItem, StoresListing, StoresTitleLine } from './search-results-styles';
import { Container } from '../../components/global'
import { ResultSearch } from '../../components/result-search/result-search';
import { getStoresList } from '../../services/api/requests/stores-listing';

const SearchResults = () => {

    const [storeData, setStoreData] = useState()
    const [searchValue, setSearchValue] = useState('')
    const [categoryValue, setCategoryValue] = useState(0)

    var alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

    const updateSearchResults = (id, stringValue) => {
        console.log('ooi',id);
        getStoresList(id, stringValue)
            .then(data => { setStoreData(data.data) })
    }

    const resetFilters = () => {
        setCategoryValue(0)
        setSearchValue('')
        updateSearchResults('', '')
    }

    const handleLetterClick = (item) => {
        updateSearchResults(categoryValue, item)
        setTimeout(() => {
            setSearchValue('')
        }, 400);
    }


    useEffect(() => {
        const textInputValue = window.location.search.substr(1, window.location.search.indexOf('&') - 1);
        const categoryString = window.location.search.substr(window.location.search.indexOf('&') + 1, window.location.search.length);
        const textResult = textInputValue.substr(7, textInputValue.length)
        const categoryResult = categoryString.substr(9, categoryString.length)

        setSearchValue(textResult)
        setCategoryValue(categoryResult)
        
        getStoresList(categoryResult, textResult)
            .then(data => { setStoreData(data.data) })

    }, [])



    const categoryHasLetter = (letter) => {
        const arrayToLoop = storeData?.data.headers?.first_chars
        if (arrayToLoop?.includes(letter.toUpperCase())) {
            return true
        } else {
            return false
        }
    }


    return (
        <SearchResultsContainer>
            <Container>
                <SearchResultsContent>
                    <SearchResultsTitle>Empresas</SearchResultsTitle>
                    <ResultSearch
                        categoryValue={categoryValue}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        setCategoryValue={setCategoryValue}
                        searchClick={() => updateSearchResults(categoryValue, searchValue)}
                    />
                    <StoresTitleLine>
                        <LineTitle>TODAS AS Salas</LineTitle>
                        <LineButton onClick={() => resetFilters()}>Ver Todas</LineButton>
                    </StoresTitleLine>
                   {
                   !searchValue && !categoryValue ?  
                   <LettersBar>{
                        alphabet.map((item, index) => {
                            return (
                                <LetterItem key={index} isActive={categoryHasLetter(item)} onClick={() => handleLetterClick(item)}>{item}</LetterItem>
                            )
                        })
                    }</LettersBar>
                    : <div className='space' />
                    }
                    <StoresListing>
                        {storeData?.data?.items?.length > 0 ?
                            <>
                                {storeData?.data?.items?.map((item, index) => {
                                    return (
                                        <StoreItem href={`/empresa/${item.slug}`} key={index}>{item.nome}</StoreItem>
                                    )
                                })}
                            </> :
                            <EmptyListMessage>Não encontramos resultados para a busca</EmptyListMessage>}
                    </StoresListing>
                </SearchResultsContent>
            </Container>
        </SearchResultsContainer>
    )
}

export default SearchResults