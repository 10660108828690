import styled from 'styled-components'

export const RoomDetailsContainer = styled.div`
    background-color: ${props => props.theme.cream};
    height: 100%;
`

export const RoomDetailsContent = styled.div`
    padding: 20px;
    @media (min-width: 1200px){
        padding: 0;
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: row-reverse;
    }
`

export const RoomDetailBanner = styled.div`
    margin-bottom: 20px;
    width: 100%;
    @media (min-width: 1200px){
        width: 40%;
        margin-left: 10%;
    }
`

export const BannerImg = styled.img`
    width: 100%;
`
export const RoomTower = styled.div`
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
    color: ${props => props.theme.secondary};
    text-transform: uppercase;
    @media (max-width: 1200px){
        text-align: center;
    }
`

export const RoomFloor = styled.div`
    color: #666;
    font-size: 12px;
    @media (max-width: 1200px){
        text-align: center;
    }
`

export const RoomName = styled.div`
    font-weight: bold;
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 5px;
    @media (max-width: 1200px){
        text-align: center;
    }
`

export const RoomDescription = styled.div`
    color: #666;
    font-size: 16px;
    margin-bottom: 30px;
    text-transform: none !important;
    @media (max-width: 1200px){
        text-align: center;
        margin-bottom: 0;
    }
`

export const RoomLogoContainer = styled.div`
    width: 250px;
    height: 250px;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 1200px){
        float: right;
        width: 200px;
        height: 200px;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 50px;
        margin-bottom: 10px;
    }
`

export const RoomLogoImg = styled.img`
    width: 150px;
    object-fit: contain;
`

export const RoomDetailText = styled.div`
    @media (min-width: 1200px){
        flex: 1;
    }
`

export const Link = styled.a`
    margin-top:30px;
    margin-bottom:30px;
    font-size:10px;
    color: ${props => props.theme.secondary};
    @media (max-width: 1200px){
       text-align:center;
       width:100%;
       display:block;
    }
`

export const RoomSocialContainer = styled.div`
    display: flex;
    margin-top: 40px;
    margin-bottom:30px;
    @media (max-width: 1200px){
       justify-content: center;
    }
`

export const SocialItem = styled.a`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${props => props.theme.secondary};
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #fff;
    text-decoration: none;
    user-select: none;
    transition: 400ms;
    cursor: pointer;
    &:hover{
        opacity: 0.8;
        transform: rotate(5deg);
    }
`

export const RoomInfoContainer = styled.div`
    margin-top: 20px;
`

export const RoomInfoItem = styled.div`
    margin-bottom: 10px;
    color: #666;
    @media (max-width: 1200px){
        text-align: center;
    }
    svg{
        color: ${props => props.theme.secondary};
        margin-right: 5px;
    }
`