import React from 'react'
import { ContentContainer, PageContainerStyles } from './page-container.styles'
import {Header, Footer} from '../global'

export const PageContainer = ({children}) => {
    return (
        <PageContainerStyles>
            <Header/>
            <ContentContainer>
                {children}
            </ContentContainer>
            <Footer/>
        </PageContainerStyles>
    )
}
