import api from "..";

export async function getStoresList(id, string) {
    try {
      let response
      if(!id.length> 1 && !string.length> 1){
        response = await api.get(`/salistas`)
      } else if (string.length > 1){
        response = await api.get(`/salistas?category=${id}&search=${string}`);
      } else{
        response = await api.get(`/salistas?category=${id}&first_char=${string}`);
      }
      return response;
    } catch (error) {
      console.error(error);
    }
}
