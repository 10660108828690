import styled from 'styled-components'

export const SearchResultsContainer = styled.div`
    background-color: ${props => props.theme.cream};
    height: 100%;
`

export const SearchResultsContent = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    .space{
        height:20px;
    }
`

export const SearchResultsTitle = styled.h1`
    font-weight: 900;
    font-size: 32px;
    margin-bottom: 40px;
    @media (max-width: 1199px){
        padding-left: 20px;
    }
`

export const StoresTitleLine = styled.div`
    background-color: ${props => props.theme.secondary};
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const LineTitle = styled.div`
    color: #fff;
    letter-spacing: 3px;
    font-weight: 300;
    text-transform: uppercase;
`

export const LineButton = styled.div`
    background-color: ${props => props.theme.primary};
    color: #fff;
    padding: 5px 20px;
    border-radius: 20px;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    &:hover{
        opacity: 0.8
    }
`

export const LettersBar = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #e1e1e1;
    flex-wrap: wrap;
    margin-bottom: 50px;
    @media (min-width: 1200px){
        justify-content: space-between;
    }
`

export const LetterItem = styled.div`
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    width: 36px;
    margin-right: 10px;
    margin-bottom: 10px;
    color: ${props => props.isActive ? props.theme.secondary : '#aaa'};
    @media (min-width: 1200px){
        width: unset;
        margin-bottom: 0;
        margin-right: 0;
    }
    &:hover{
        color: ${props => props.theme.secondary}
    }
`

export const StoresListing = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    @media (min-width: 1200px){
        padding: 0;
    }
`

export const StoreItem = styled.a`
    border-bottom: 1px solid #aaa;
    text-align: center;
    width: 45%;
    padding-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-right: 10%;
    margin-bottom: 50px;
    cursor: pointer;
    transition: 400ms;
    text-decoration: none;
    color: ${props => props.theme.primary};
    &:hover{
        color: ${props => props.theme.secondary};
    }
    &:nth-child(2n){
        margin-right: 0;
    }
    @media (min-width: 1200px){
        width: 30%;
        margin-right: 5%;
        &:nth-child(2n){
            margin-right: 5%;
        }

        &:nth-child(3n){
            margin-right: 0%;
        }
    }
`

export const EmptyListMessage = styled.div`
    font-style: italic;
    text-align: center;
    font-weight: 700;
    width: 100%;
    font-size: 22px;
`