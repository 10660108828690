import React, { useState, useEffect } from 'react'
import { getHomeCategories } from '../../services/api/requests/home-categories'
import { Container } from '../global'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { ResultSearchButton, ResultSearchContainer, ResultSearchInput, ResultSearchSelect, ContainerInput, LabelInput, InputsRow } from './result-search.styles'

export const ResultSearch = (props) => {
    const { setSearchValue, setCategoryValue, searchClick, searchValue, categoryValue } = props
    const [categories, setCategories] = useState()
    useEffect(() => {
        getHomeCategories()
            .then(data => { setCategories(data.data) })
    }, [])

    const handleChange = (e) => {
        setCategoryValue(e.target.value);
    }

    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if(searchClick){
                searchClick()
            }
         }
     }

    return (
        <ResultSearchContainer>
            <Container>
                <InputsRow>
                    <ContainerInput>
                        <ResultSearchSelect className='input-base' onChange={handleChange} defaultValue={0} value={categoryValue}>
                            <option value="" selected disabled hidden></option>
                            <option value="">Todas</option>
                            {categories?.data.items.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            })}
                        </ResultSearchSelect>
                        <LabelInput>Categoria</LabelInput>
                    </ContainerInput>
                    <ContainerInput>
                        <ResultSearchInput className='input-base' onChange={handleInputChange}  onKeyDown={handleKeyDown}  value={searchValue} />
                        <LabelInput required class="floating-label">Buscar</LabelInput>
                    </ContainerInput>
                    <ResultSearchButton onClick={searchClick}><FontAwesomeIcon icon={faSearch} /></ResultSearchButton>
                </InputsRow>
            </Container>
        </ResultSearchContainer>
    )
}
