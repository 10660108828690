import React, {useState} from 'react'
import { CategoriesMenu, Container,  BurgerButton } from '../index'
import { HeaderContainer, HeaderContent, HeaderItemContainer, HeaderLogoContainer, LogoImage,Title } from './header.styles'

const Header = () => {
    const [isCategoriesMenuActive, setIsCategoriesMenuActive] = useState(false)
    return (
        <HeaderContainer>
            <Container>
                <HeaderContent>
                    <HeaderLogoContainer href='/'>
                        <LogoImage/>
                    </HeaderLogoContainer>
                    <Title>GUIA DE NEGÓCIOS</Title>
                    <HeaderItemContainer>
                        <BurgerButton burgerClick={() => setIsCategoriesMenuActive(!isCategoriesMenuActive)}/>
                    </HeaderItemContainer>
                    <CategoriesMenu isActive={isCategoriesMenuActive}/>
                </HeaderContent>
            </Container>
        </HeaderContainer>
    )
}

export default Header