import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        height: 100vh;
        overflow-x: hidden;
        font-family: 'Roboto';
    }
    #root{
        height: 100%;
    }
    .swiper-pagination-bullet{
        width: 10px !important;
        height: 10px !important;
        background-color: transparent;
        border: 3px solid #000;
    }

    .swiper-pagination-bullet-active{
        background-color: ${props => props.theme.secondary};
        border-color: ${props => props.theme.secondary}
    }

`;
 
export default GlobalStyle;