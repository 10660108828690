import React, { useState, useEffect } from 'react'
import { FooterContainer, FooterContent, FooterSocial, SocialItem, SocialLogo, AddressText, Title, ConteinerIcon } from './footer.styles'
import youtubeImage from '../../../assets/images/youtube.png'
import instagramImage from '../../../assets/images/instagram.png'
import facebookImage from '../../../assets/images/facebook.png'
import whatsappImage from '../../../assets/images/whatsapp.png'
import linkedinImage from '../../../assets/images/linkedin.png'
import { Container } from '../index'
import { getFooterLinks } from '../../../services/api/requests/footer-links'




const Footer = () => {
    const [socialNetworkData, setSocialNetworkData] = useState()

    useEffect(() => {
        getFooterLinks()
            .then(data => { setSocialNetworkData(data.data) })
    }, [])


    const filteredNumberNorte = socialNetworkData?.data?.whatsapp_norte?.replace(/[^A-Z0-9]+/ig, "")
    const filteredNumberSul = socialNetworkData?.data?.whatsapp_sul?.replace(/[^A-Z0-9]+/ig, "")

    const socialNetwork = [
        { logo: whatsappImage, link: `https://wa.me/55${filteredNumberNorte}`, title: 'TORRE NORTE' },
        { logo: whatsappImage, link: `https://wa.me/55${filteredNumberSul}`, title: 'TORRE SUL' },
        { logo: youtubeImage, link: socialNetworkData?.data.youtube },
        { logo: instagramImage, link: socialNetworkData?.data.instagram },
        { logo: facebookImage, link: socialNetworkData?.data.facebook },
        { logo: linkedinImage, link: socialNetworkData?.data.linkedin },
    ].filter(e => !!e.link)

    return (
        <FooterContainer className='footer'>
            <Container >
                <FooterContent className="content">
                    <div>
                        <AddressText>{socialNetworkData?.data?.address}</AddressText>
                        <AddressText>{socialNetworkData?.data?.address_complement}</AddressText>
                        <AddressText className='number'>TORRE NORTE: {socialNetworkData?.data?.phone1}</AddressText>
                        <AddressText className='last' >TORRE SUL: {socialNetworkData?.data?.phone1}</AddressText>
                    </div>
                    <FooterSocial>
                        {socialNetwork.map((item, index) => {
                            return (
                                    <SocialItem large={item.title} href={item.link} target='_blank'>
                                        <ConteinerIcon>
                                            <SocialLogo src={item.logo} />
                                        </ConteinerIcon>
                                        {!!item.title && <Title>{item.title}</Title>}
                                    </SocialItem>

                            )
                        })}
                    </FooterSocial>
                </FooterContent>
            </Container>
        </FooterContainer>
    )
}

export default Footer
