import styled from 'styled-components'

export const SliderContainer = styled.div`

`

export const SlideImage = styled.img`
    width: 100%;
    object-fit: contain;
`

export const SlideImageMobile = styled.img`
    width: 100%;
    object-fit: contain;
`

export const ImageLink = styled.a`
    width: 100%;
    height: 100%;
`