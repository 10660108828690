import React, { useState, useEffect } from 'react'
import { getHomeCategories } from '../../services/api/requests/home-categories'
import { Container } from '../global'
import { HomeSearchButton, HomeSearchContainer, HomeSearchInput, HomeSearchSelect, InputsRow, ContainerInput, LabelInput } from './home-search.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export const HomeSearch = () => {
    const [categories, setCategories] = useState()
    const [selectedCategory, setSelectedCategory] = useState('')
    const [searchInputValue, setSearchInputValue] = useState('')
    useEffect(() => {
        getHomeCategories()
            .then(data => { setCategories(data.data) })
    }, [])

    const handleChange = (e) => {
        setSelectedCategory(e.target.value);
    }

    const handleInputChange = (e) => {
        setSearchInputValue(e.target.value);
    }

    const handleKeyDown = (e) => {
       if (e.key === 'Enter') {
        window.location.href= (searchInputValue || selectedCategory) ? `/resultado?search=${searchInputValue}&category=${selectedCategory}` : `/resultado`;
        }
    }

    return (
        <HomeSearchContainer>
            <Container>
                <InputsRow>
                    <ContainerInput>
                            <HomeSearchSelect className='input-base' onChange={handleChange} required>
                                <option value="" selected disabled hidden></option>
                                <option value="">Todas</option>
                                {categories?.data.items.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                })}
                            </HomeSearchSelect>
                            <LabelInput>Categoria</LabelInput>
                    </ContainerInput>
                    <ContainerInput>
                        <HomeSearchInput className='input-base' onChange={handleInputChange} onKeyDown={handleKeyDown} autocomplete="off" />
                        <LabelInput required class="floating-label">Buscar</LabelInput>
                    </ContainerInput>
                    <HomeSearchButton href={(searchInputValue || selectedCategory) ? `/resultado?search=${searchInputValue}&category=${selectedCategory}` : `/resultado`}><FontAwesomeIcon icon={faSearch} /></HomeSearchButton>
                </InputsRow>
            </Container>
        </HomeSearchContainer>
    )
}
