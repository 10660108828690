import styled from 'styled-components'

export const CategoriesMenuContainer = styled.div`
    width: 160px;
    position: absolute;
    top: 100px;
    right: 20px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 2px 15px 2px rgba(0,0,0,0.09);
    transition: 400ms;
    opacity: ${props => props.opacityState ? 1 : 0};
    text-align: right;
    border-radius: 6px;
    z-index: 10;
    display: ${props => props.isActive ? 'block' : 'none'};
    min-height: calc(100vh - 150px);
    height: 1px;
    overflow-y: auto;
    
`

export const CategoriesMenuItem = styled.a`
    text-transform: uppercase;
    text-align: right;
    margin-bottom: 15px;
    color: #aaa;
    display: block;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    &:hover{
        color: ${props => props.theme.secondary}
    }
`