import api from "..";

export async function getHomeSlides() {
    try {
      const response = await api.get('/slideshows');
      return response;
    } catch (error) {
      console.error(error);
    }
}
