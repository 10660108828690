import styled from 'styled-components'

export const HomeSearchContainer = styled.section`
    background-color: ${props => props.theme.primary};
    padding-top: 40px;
    padding-bottom: 40px;
`

export const InputsRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    @media (min-width: 1200px){
        flex-direction: row;
        padding: 0;
    }
`

export const ContainerInput = styled.div`
display: flex;
flex:1;
position: relative;


`

export const LabelInput = styled.label`
        font-size: 18px;
        color: #fff;
        letter-spacing: 3px;
        text-transform: uppercase;

        position: absolute;
		pointer-events: none;
		top: 0px;
		left: 4px;
		transition: all 0.1s ease;

        .input-base:focus ~ &,
        .input-base:not(:focus):valid ~ & {
            top: -15px;
            bottom: 0px;
            left: 0px;
            font-size: 11px;
            opacity: 1;
        }
	
`

export const HomeSearchInput = styled.input.attrs({ required: true })`
    border: 0;
    outline: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    background-color: transparent;
    font-size: 18px;
    margin-bottom: 40px;
    width: 100%;
    margin-right: 0px;
    @media (min-width: 1200px){
        margin-right: 20px;
        margin-bottom: 0;
    }
`

export const HomeSearchSelect = styled.select`
    border: 0;
    outline: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    background-color: transparent;
    font-size: 18px;
    margin-bottom: 40px;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-right: 0px;
    @media (min-width: 1200px){
        margin-right: 20px;
        margin-bottom: 0;
    }
    &::placeholder{
        color: #fff;
        letter-spacing: 3px;
        text-transform: uppercase;;
    }
    option{
        letter-spacing: 3px;
        text-transform: uppercase;
    }
    option:not(:checked) { background: #666; }
    option:checked { 
        background-color: ${props => props.theme.secondary} ; 
    }
`

export const HomeSearchButton = styled.a`
    border: 1px solid ${props => props.theme.secondary};
    border-radius: 20px;
    padding: 5px 20px;
    color: #aaa;
    text-transform: uppercase;
    width: fit-content;
    letter-spacing: 3px;
    font-weight: 300;
    outline: none;
    transition: 400ms;
    text-align: center;
    height:fit-content ;
    text-decoration: none;
    cursor: pointer;
    svg{
        transition: 400ms;
        color: ${props => props.theme.secondary};
    }
    &:hover{
        background-color: ${props => props.theme.secondary};
        color: #fff;
        svg{
            color: #fff;
        }
    }
`