import styled from 'styled-components'

import LogoImg from '../../../assets/images/bspar-logo-footer.png'

export const FooterContainer = styled.div`
   width: 100%;
   background: ${props => props.theme.primary};
`;

export const FooterContent = styled.div`
   padding-top: 25px;
   padding-bottom: 25px;
   display: flex;
   justify-content: space-between;
   color: #fff;
   align-items: center;
   position: relative;
   flex-direction: column;
   @media(min-width: 1200px){
      flex-direction: row;
   }
`

export const AddressText = styled.div`
 color: #fff;
 font-weight:300;
 font-size:14px;
 &.number{
   margin-top:10px;
 }
 &.last{
   margin-bottom:30px;
 }
`

export const LogoContainer = styled.div`
   width: 150px;
   margin-bottom: 30px;
   @media(min-width: 1200px){
      margin-bottom: 0;
   }
`

export const FooterLogo = styled.img.attrs({
   src: LogoImg
})`
   width: 100%;
`

export const FooterSocial = styled.div`
   display: flex;
   @media(min-width: 1200px){
      justify-content: flex-end;
   }
`

export const SocialItem = styled.a`
   width: fit-content                                                          ;
   justify-content: space-between ;
   background-color: #fff;
   height: 50px;
   border-radius: 20px;
   display: flex;
   align-items: center;
   align-content:space-between ;
   color: ${props => props.theme.primary};
   margin-left: 10px;
   text-decoration:none ;
   margin-right: 10px;
   transition: 400ms;
   overflow: hidden;
   &:last-child{
      margin-right: 0;
   }
   @media(min-width: 1200px){
      height: 40px;
      flex-direction: row;
   }
`

export const SocialLogo = styled.img`
   width: 100%;
   height: 100%;
   object-fit:contain;
`
export const ConteinerIcon = styled.div`
   overflow:hidden ;
   padding: 0;
   height: 50px;
   border-radius:50% ;
   @media(min-width: 1200px){
      height: 40px;
      }
`


export const Title = styled.div`
font-size:12px ;
font-weight:bold ;
margin-right: 14px;
`