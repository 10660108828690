import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube, faInstagram, faWhatsapp, faLinkedinIn, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { useParams } from "react-router-dom";
import { Container } from '../../components/global'
import { HomeSearch } from '../../components/home-search/home-search';
import { getRoomDetail } from '../../services/api/requests/room-details';
import { BannerImg, RoomDescription, RoomDetailBanner, RoomDetailsContainer, RoomDetailsContent,Link, RoomDetailText, RoomFloor, RoomInfoContainer, RoomInfoItem, RoomLogoContainer, RoomLogoImg, RoomName, RoomSocialContainer, RoomTower, SocialItem } from './room-details.styles';
import { faClock, faPhone, faGlobeAfrica, faEnvelope } from '@fortawesome/free-solid-svg-icons'

export const RoomDetails = () => {
    let { id } = useParams()
    const [roomData, setRoomData] = useState()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        getRoomDetail(id)
            .then(data => { setRoomData(data.data.data) })
    }, [id])

    useEffect(() => {
        const { innerWidth: width } = window
        if (width < 1200) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [])

    const filteredNumber = roomData?.whatsapp?.replace(/[^A-Z0-9]+/ig, "")


    return (
        <RoomDetailsContainer>
            <HomeSearch />
            <Container>
                <RoomDetailsContent>
                    <RoomDetailBanner>
                        <BannerImg src={isMobile ? roomData?.imagem_mobile : roomData?.imagem_desktop} />
                    </RoomDetailBanner>
                    <RoomDetailText>
                        {roomData?.categoria && <RoomTower>{roomData?.categoria.join(" | ")}</RoomTower>}
                        <RoomName>{roomData?.nome}</RoomName>
                        <RoomFloor>{`${roomData?.torre} | ${roomData?.andar} ${(roomData?.andar && roomData.sala) ? '|' : ''} ${roomData?.sala}`}</RoomFloor>
                        <RoomDescription>{roomData?.legend}</RoomDescription>
                        <RoomLogoContainer>
                            <RoomLogoImg src={roomData?.logo} />
                        </RoomLogoContainer>
                        <RoomDescription>{roomData?.content}</RoomDescription>
                        <RoomInfoContainer>
                            {!!roomData?.horario_de_funcionamento && <RoomInfoItem>
                                <FontAwesomeIcon icon={faClock} /> {roomData?.horario_de_funcionamento}
                            </RoomInfoItem>}
                            {!!roomData?.telefone_de_contato && <RoomInfoItem>
                                <FontAwesomeIcon icon={faPhone} /> {roomData?.telefone_de_contato}
                            </RoomInfoItem>}
                        </RoomInfoContainer>
                        <RoomSocialContainer>
                            {!!roomData?.site &&
                                <SocialItem href={roomData?.site} target='_blank'>
                                    <FontAwesomeIcon icon={faGlobeAfrica} />
                                </SocialItem>
                            }
                            {!!roomData?.email &&
                                <SocialItem href={`mailto:${roomData?.email}`}>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </SocialItem>
                            }
                            {!!roomData?.instagram &&
                                <SocialItem href={roomData?.instagram} target='_blank'>
                                    <FontAwesomeIcon icon={faInstagram} />
                                </SocialItem>
                            }
                            {!!roomData?.facebook &&
                                <SocialItem href={roomData?.facebook} target='_blank'>
                                    <FontAwesomeIcon icon={faFacebookF} />
                                </SocialItem>
                            }
                            {!!filteredNumber &&
                                <SocialItem href={`https://wa.me/55${filteredNumber}`} target='_blank'>
                                    <FontAwesomeIcon icon={faWhatsapp} />
                                </SocialItem>
                            }
                            {!!roomData?.linkedin &&
                                <SocialItem href={roomData?.linkedin} target='_blank'>
                                    <FontAwesomeIcon icon={faLinkedinIn} />
                                </SocialItem>
                            }
                            {!!roomData?.youtube &&
                                <SocialItem href={roomData?.youtube} target='_blank'>
                                    <FontAwesomeIcon icon={faYoutube} />
                                </SocialItem>
                            }
                        </RoomSocialContainer>
                        <Link href='/' >Voltar ao início</Link>
                    </RoomDetailText>
                </RoomDetailsContent>
            </Container>
        </RoomDetailsContainer>
    )
}
