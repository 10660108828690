import styled from 'styled-components'
import logoDesktop from '../../../assets/images/logo-bsdesign.png'

export const HeaderContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    box-shadow: 0px 9px 5px 0px rgba(0,0,0,0.15);
    z-index: 999999999;
    background-color: #fff;
    @media (min-width: 1200px){
        height: 90px;
    }
`

export const Title = styled.div`
    text-align:center;
    flex:1;
    font-size:14px;
    @media (min-width: 1200px){
        font-size:20px;
    }
`

export const HeaderContent = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    padding-top: 5px;
    justify-content: space-between;
    position: relative;
    @media (min-width: 1200px){
        padding: 0;
    }
`

export const HeaderLogoContainer = styled.a`
   flex: 1;
`

export const LogoImage = styled.img.attrs({
    src: logoDesktop
})`
    width: 100px;
    @media (min-width: 1200px){
        width: 150px;
    }
`

export const HeaderItemContainer = styled.div`
    flex: 1;
    user-select: none;
    display: flex;
    align-items: flex-end;
`

export const SearchButton = styled.div`
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 3px;
    color: #aaa;
    transition: 400ms;
    cursor: pointer;
    display: inline-flex;
    margin-right: 20px;
    &:hover{
        color: ${props => props.theme.secondary};
    }
    @media (min-width: 1200px){
        display: none;
    }
`