import api from "..";

export async function getFooterLinks() {
    try {
      const response = await api.get('/options');
      return response;
    } catch (error) {
      console.error(error);
    }
}
