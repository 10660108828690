import styled from 'styled-components'

export const PageContainerStyles = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

export const ContentContainer = styled.div`
    flex: 1;
    padding-top: 80px;
    @media (min-width: 1200px){
        padding-top: 90px
    }
`