import api from "..";

export async function getHomeCategories() {
    try {
      const response = await api.get('/salistas-categorias');
      return response;
    } catch (error) {
      console.error(error);
    }
}
