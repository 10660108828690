import styled from 'styled-components'

export const HomeCategoriesContainer = styled.section`
    padding-top: 10px;
    padding-bottom: 10px;
    @media (min-width: 1200px){
        padding-top: 30px;
        padding-bottom: 30px;
    }
`

export const ListButton = styled.div`
    margin-top: 50px;
    text-align: center;
`

export const BlackButton = styled.a`
    background-color: ${props => props.theme.primary};
    color: #fff;
    padding: 5px 20px;
    border-radius: 20px;
    display: inline-flex;
    cursor: pointer;
    transition: 400ms;
    user-select: none;
    text-decoration: none;
    &:hover{
        background-color: ${props => props.theme.secondary};
    }
`

export const CategoriesListing = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    @media (min-width: 1200px){
        padding: 0;
    }
`

export const CategoryIcon = styled.img`
    width: 80px;
    margin-bottom: 10px;
    transition: 400ms;
`

export const CategoryLabel = styled.div`
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: bold;
    font-size: 12px;
    @media (min-width: 1200px){
        font-size: 14px;
    }
`

export const CategoryItem = styled.a`
    width: 44%;
    border-radius: 40px;
    padding: 2%;
    margin: 1%;
    background: linear-gradient(180deg, rgba(232,142,75,1) 24%, rgba(226,113,28,1) 64%);
    text-align: center;
    transition: 400ms;
    user-select: none;
    text-decoration: none;
    cursor: pointer;
    @media (min-width: 1200px){
        width: 14%;
        padding: 2%;
        margin: 1%;
    }
    &:nth-child(5n){
        margin-right: 0;
    }
    &:hover{
        opacity: 0.8;
        @media (min-width: 1200px){
            img{
                transform: scale(1.2);
            }
        }
    }
`