import styled from 'styled-components'

export const BurgerButtonContainer = styled.div`
    margin-left: auto;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;
    user-select: none;
`

export const BurgerLineOne = styled.div`
    width: 30px;
    height: 3px;
    border-radius: 6px;
    background-color: ${props => props.theme.secondary};
    margin-bottom: 5px;
    transition: 400ms;
    transform: ${props => props.isActive && 'translateY(6px) rotate(45deg)'};
    user-select: none;
`

export const BurgerLineTwo = styled.div`
    width: 30px;
    height: 3px;
    border-radius: 6px;
    background-color: ${props => props.theme.secondary};
    margin-bottom: 5px;
    transition: 400ms;
    opacity: ${props => props.isActive && 0};
    user-select: none;
`

export const BurgerLineThree = styled.div`
    width: 30px;
    height: 3px;
    border-radius: 6px;
    background-color: ${props => props.theme.secondary};
    margin-bottom: 5px;
    transition: 400ms;
    transform: ${props => props.isActive && 'translateY(-10px) rotate(-45deg)'};
    user-select: none;
`