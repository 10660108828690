import React, {useState} from 'react'
import { BurgerButtonContainer, BurgerLineOne, BurgerLineThree, BurgerLineTwo } from './burger-button.styles'

const BurgerButton = ({burgerClick}) => {
    const [isActive, setIsActive] = useState(false)

    return (
        <BurgerButtonContainer onClick={() => {
            setIsActive(!isActive)
            burgerClick()
        }}>
            <BurgerLineOne isActive={isActive}/>
            <BurgerLineTwo isActive={isActive}/>
            <BurgerLineThree isActive={isActive}/>
        </BurgerButtonContainer>
    )
}

export default BurgerButton