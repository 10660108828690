import React, {useState, useEffect} from 'react'
import { Container } from '../global'
import { BlackButton, CategoriesListing, CategoryIcon, CategoryItem, CategoryLabel, HomeCategoriesContainer, ListButton } from './home-categories.styles'
import { getHomeCategories } from '../../services/api/requests/home-categories'

export const HomeCategories = () => {
    const [categoriesData, setCategoriesData] = useState()
    useEffect(() => {
        getHomeCategories()
            .then(data => {setCategoriesData(data.data)})
    }, [])

    return (
        <HomeCategoriesContainer>
            <Container>
                <CategoriesListing>
                    {categoriesData?.data.items.map((item, index) => {
                        return (
                            <CategoryItem key={index} href={`resultado?&category=${item.id}`}>
                                <CategoryIcon src={item.icon}/>
                                <CategoryLabel>{item.name}</CategoryLabel>
                            </CategoryItem>
                        )
                    })}

                </CategoriesListing>
                    <ListButton>
                        <BlackButton href='/resultado'>VER TODOS</BlackButton>
                    </ListButton>
            </Container>
        </HomeCategoriesContainer>
    )
}
