import React, {useState, useEffect} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { getHomeSlides } from '../../services/api/requests/home-slides';
import { ImageLink, SlideImage, SlideImageMobile, SliderContainer } from './home-slider.styles';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Container } from '../global';

export const HomeSlider = () => {
    SwiperCore.use([Autoplay]);
    const [slidesData, setSlidesData] = useState()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        getHomeSlides()
            .then(data => {setSlidesData(data.data)})
    }, [])

    useEffect(() => {
        const { innerWidth: width} = window
        if(width < 1200){
            setIsMobile(true)
        } else{
            setIsMobile(false)
        }
    }, [])

    return (
        <SliderContainer>
            <Container>
                <Swiper
                spaceBetween={50}
                slidesPerView={1}
                modules={[Pagination, Autoplay]}
                pagination={{ clickable: true }}
                loop={true}
                autoplay={{
                    disableOnInteraction: false,
                    delay: 4500
                }}
                >
                {slidesData?.data.items.map((item, index) => {
                    return(
                        
                        <SwiperSlide key={index}>
                            <ImageLink href={item.link} target='_blank'>
                            {isMobile ?
                                <SlideImageMobile src={item.imagem_mobile} alt=""/>:
                                <SlideImage src={item.imagem_desktop} alt=""/>}
                            </ImageLink>
                        </SwiperSlide>
                    )
                })}
                </Swiper>
            </Container>
        </SliderContainer>
    )
}
