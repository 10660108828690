import React from 'react';
import { HomeSlider } from '../../components/home-slider/home-slider';
import { HomeCategories } from '../../components/home-categories/home-categories';
import { HomeContainer } from './home.styles';
import { HomeSearch } from '../../components/home-search/home-search';


const Home = () => {
    return(
        <HomeContainer>
            <HomeSlider/>
            <HomeSearch/>
            <HomeCategories/>
        </HomeContainer>
    ) 
}

export default Home;