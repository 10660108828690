import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import { PageContainer } from "../../components/page-container/page-container";
import GlobalStyle from "../../theme/global-style";
import { theme } from "../../theme/theme";
import Home from "../Home/home.jsx";
import { RoomDetails } from "../RoomDetails/room-details";
import SearchResults  from "../SearchResults/search-results";


function App() {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle/>
            <PageContainer>
                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="/resultado" element={<SearchResults/>} />
                    <Route path="/empresa/:id" element={<RoomDetails/>} />
                </Routes>
            </PageContainer>
        </ThemeProvider>
    );
}

export default App;
